import { SWRConfig } from "swr";
import Layout from "../components/layout";
import "../styles/globals.sass";

function MyApp({ Component, pageProps }) {
  const headerTitle = Component.headerTitle || "Euforia";
  const showBack = Component.showBack || false;
  const withoutLayout = Component.withoutLayout || false;

  const Body = () => (
    <SWRConfig
      value={{
        onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
          console.log(error);
          if (error.status === 404) return;
          if (retryCount >= 5) return;
          setTimeout(() => revalidate({ retryCount }), 2000);
        },
        fetcher: async (url) => {
          const res = await fetch(url);
          if (res.ok) {
            return await res.json();
          }

          const error = new Error("An error occurred while fetching the data.");
          error.status = res.status;
          if (res.status === 404) {
            error.info = "Неверная ссылка или обслуживание специалиста приостановлено";
          } else {
            error.info = (await res.json()).message;
          }
          throw error;
        },
      }}
    >
      <Component {...pageProps} />
    </SWRConfig>
  );

  if (withoutLayout) {
    return <Body />;
  }

  return (
    <Layout headerTitle={headerTitle} showBack={showBack}>
      <Body />
    </Layout>
  );
}

export default MyApp;
