import Image from "next/image";
import styles from "../styles/Footer.module.sass";

export default function Footer() {
  return (
    <footer className={styles.footer}>
      <a href="https://euforia.tech" target="_blank" rel="noopener noreferrer">
        <Image src="/euforia.svg" width={62} height={29} alt="euforia" />
      </a>
    </footer>
  );
}
