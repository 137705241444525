import Header from "./header";
import Footer from "./footer";
import styles from "../styles/Layout.module.sass";

export default function Layout({ headerTitle, showBack, children }) {
  return (
    <>
      <Header title={headerTitle} showBack={showBack} />
      <main className={styles.layout}>{children}</main>
      <Footer />
    </>
  );
}
