import { useRouter } from "next/router";
import styles from "../styles/Header.module.sass";

export default function Header({ title, showBack }) {
  const router = useRouter();

  return (
    <header className={styles.header}>
      <div className={styles.back_line}>
        <p className={styles.back_line__fake}></p>
        {/* {showBack && (
          <p className={styles.back_line__link} onClick={router.back}>
            <span className={styles.back_line__link__arrow}>ᐸ</span> Назад
          </p>
        )} */}
      </div>
      <p className={styles.title}>{title}</p>
    </header>
  );
}
